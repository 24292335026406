import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useServiceMetaData } from '../../components/hooks/schema/servicesSchema'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import SiteWidthWrapper from '../../components/wrappers/siteWidthWrapper'
import CallOut from '../../components/callOut'
import SplashTwo from '../../components/splashTwo'

const BreakInPage = () => {
  const data = useServiceMetaData()

  const ServiceSchema = ` 
    {
      "@context": "https://schema.org/",
      "@type": "Service",
      "serviceType": "24 hour break in repair",
      "provider": {
        "@type": "LocalBusiness",
        "name": "${data.title}",
        "image": "${data.siteUrl}${data.siteLogo}",
        "address": "${data.businessAddress}",
        "telephone": "${data.businessNumber}",
        "priceRange": "$$ - $$$$"
      },
      "areaServed": {
            "@type": "GeoCircle",
            "geoMidpoint": {
                "@type": "GeoCoordinates",
                "latitude": ${data.geoLon}
                "longitude": ${data.geoLat}
            },
            "geoRadius": ${data.geoRad}
        },
      "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "Asbestos Removal Services",
        "itemListElement": [
          {
            "@type": "OfferCatalog",
            "name": "Asbestos Removal Services",
            "itemListElement": [
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "Asbestos Removal Services"
                }
              },
              {
                "@type": "Offer",
                "itemOffered": {
                  "@type": "Service",
                  "name": "asbestos remediation services"
                }
              }
            ]
          }
        ]
      }
    }
  `

  return (
    <Layout>
      <Seo
        title="24 Hour Break-in Repair Services"
        description="We provide 24 hour emergency and non-emergency break in repair services to homes and businesses."
        canonical="https://ecotechrestoration.ca/services/break-in-repair/"
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: ServiceSchema }}
      />
      {/* Heros */}
      <div className="heroWrapper">
        <StaticImage
          className="heroImage"
          src="../../images/heros/breakin.jpg"
          quality={95}
          alt="24 Hour Break-in Repair Services"
        />
        <div className="overlay" />
        <h1 className="services__heading heroText">
          24 hour Break In Repair Services
        </h1>
      </div>

      <SiteWidthWrapper>
        <CallOut>Do you have a Break-In Repair Emergency?</CallOut>
        <p>
          Ecotech Restoration provides 24 hour emergency break in repairs to
          home and business owners. Our 24/7 team of technicians is available to
          repair any damage caused on your property, including bio-hazard
          damage.
        </p>
        <p>
          Call us if you properties been burglarized and our technicians will
          begin restoring your property back to it’s as-was condition.
        </p>
        <h2>Some Break In Repair Services We Provide</h2>
        <ul>
          <li>door &amp; door frame repairs</li>
          <li>window &amp; window frame repairs</li>
          <li>general clean up services</li>
          <li>broken glass removal</li>
          <li>clean up any odors or spills</li>
          <li>We work with insurance companies.</li>
          <li>
            We will work with your insurance company if the damage done to your
            property falls within an insurance claim.
          </li>
        </ul>
        <SplashTwo title="Break In Repairs" />
      </SiteWidthWrapper>
    </Layout>
  )
}

export default BreakInPage
